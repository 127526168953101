import React from "react"
import Layout from "../../components/layout-tips"

export default () => (
  <Layout title="Special Needs Students">
    <h2>Special Needs Students</h2>
    <div className="clear-block">
      <div id="node-27" className="node">


        <div className="content clear-block">
          <p><img src="/images/030-sean-250x333.JPG" className="float-left" width="250"
                  height="333" alt=""/> I would like to share a few of our many stories with you where the Animal Note method
            has helped someone with a special need. </p>
          <p>About 20 years ago when the Animal Notes were just stories about each note to try to help my students
            distinguish one note from another, I was asked to teach a young boy with learning disabilities. His doctor
            hoped learning music might help him. The word clues just did not help him, so I started writing simple songs
            for him making the notes into animal faces. He loved it and within two years he was playing standard music.
            About that time I was invited to his yearly review of progress at his school. His teachers and doctor were
            amazed at the difference his piano had made in his life. He moved the next year, so I never had the honor of
            seeing him grow further in his adventure with music.</p>
          <p>Sean, was another young student who started with me when he was 5 years old. His older sisters took from
            me, and when he was five his mother asked me if we could start him. We knew he had learning problems, but he
            had not been diagnosed at that time. We worked with the Animal Note method for quite a while as learning
            came slow to him at this point. Thirteen years later he was the honored student at his senior recital. He
            loved “Hoist the Colors” from the “Pirate of the Caribbean” movie and he had a lot of fun improvising with
            this piece. We always finished our lessons with it, but he had not included it in his recital pieces. After
            a standing ovation for his performance, he sat back down at the piano to finish the day with “Hoist the
            Colors”, then hugged me and said. “Thanks to the Animal Notes, Miss Glenna was able to teach me to play.
            Thank you so much.”</p>
          <p>Mike is a mid-aged gentleman, who really wanted to learn to play melody on the guitar. His grandson is
            taking from me, and his wife asked if I would be willing to teach him. I told her I would be glad to if he
            would be willing to use the Animal Note Guitar book since it is the best method I know about to teach note
            reading on the guitar. He started the next week. Mike had never been able to note read, but his wife, who
            plays the piano, now feels he reads music better than she does. He now plays hymns and will soon start
            playing for his church. He so sincerely loves what he is doing. He has enjoyed the simple way the Animal
            Notes helped him to learn to note read. It has been a great experience, and we enjoy working together.</p>
          <p>You might think the “Animal Notes” are two childish for an older child surely not for an adult. But are
            they, if this method is an avenue to success? Associative learning is not just for the young, it helps us
            all to learn. </p>
          <p>The Animal Note method is also a wonderful way to open the world of music to an individual with unusual
            special needs. A couple of children, I have personally taught, loved music with a passion, but were not able
            to learn in the normal way because of mental handicaps. With patience, I helped them to guide their hands
            over the keyboard at first. Because I was willing to work with them, they learned to play simple tones and
            develop their abilities to playing what they heard by ear. One went on to teach herself to play the guitar,
            which amazed everyone. The “Animal Notes” have opened a window in their limited worlds.</p>
          <h3>Testimonial</h3>
          <p><i>" I just want to have everything you offer as I think your ideas are amazing. I now have about 10 little
            students, ages two to four, including one special needs child."<br/>
            - Diana</i></p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
